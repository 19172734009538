import React, { useEffect, useState } from "react";
import CoursesTableRow from "./CoursesTableRow";
import { fetchCoursesAPI } from "../../../API/admin/AdminAPI";
import AddCourses from "../CreateForms/AddCourses";

const CoursesTable = ({ collegeData, setIsAddCourse, isAddCourse }) => {
  const [courseData, setCourseData] = useState([]);
  // const [isAddCourse, setIsAddCourse] = useState(false);
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await fetchCoursesAPI(collegeData._id);
        if (response?.status === 200) {
          setCourseData(response?.data);
        } else {
          setCourseData([]);
        }
      } catch (error) {
        setCourseData([]);
      }
    };
    fetchCourseData();
  }, [collegeData]);
  const handleUpdateCourse = (updatedCourse) => {
    setCourseData((prevCourses) =>
      prevCourses.map((course) =>
        course._id === updatedCourse._id ? updatedCourse : course
      )
    );
  };


  return (
    <>
      {!isAddCourse ? (
        <div className="mt-10 p-3 rounded-md shadow-lg shadow-gray-300 bg-gray-50 max-h-[400px] overflow-y-scroll">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Course
                </th>
                <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Duration
                </th>
                <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Course Fees
                </th>
                <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px] ">
                  Application Fees
                </th>
                <th className="border border-1 border-solid text-center p-[8px]">
                  <span
                    onClick={() => setIsAddCourse(true)}
                    className="border bg-gray-600  px-3 rounded-lg text-xs py-1 text-left text-white hover:bg-gray-200 hover:text-gray-600 font-bold cursor-pointer transition "
                  >
                    Add+
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {courseData.length > 0
                ? courseData.map((course) => (
                    <CoursesTableRow course={course} key={course._id} onUpdateCourse={handleUpdateCourse} // Pass the update handler
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <AddCourses
            courseData={courseData}
            setCourseData={setCourseData}
            collegeData={collegeData}
            setIsAddCourse={setIsAddCourse}
          />
        </div>
      )}
    </>
  );
};

export default CoursesTable;
