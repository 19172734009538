import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { fetchStudentOfferLetterDataAPI } from "../../API/admin/GenerateStudentDocuments";
import { useLocation, useParams } from "react-router-dom";

function OfferLetter() {
  const handleDownload = () => {
    window.print();
  };
  const [studentOfferLetterData, setStudentOfferLetterData] = useState({});
  console.log(studentOfferLetterData);

  // const studentId = useParams();
  const { studentId } = useParams();
  console.log(studentId);
  const fetchStudentOfferLetterData = async () => {
    try {
      const response = await fetchStudentOfferLetterDataAPI(studentId);
      console.log(response);
      if (response.status === 200) {
        setStudentOfferLetterData(response.data);
        document.title = `${response?.data?.name}-conditional-offer-letter`;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStudentOfferLetterData();
  }, []);

  // Create a new Date object representing the current date and time
  const today = new Date();

  // Extract the day, month, and year from the Date object
  const day = String(today.getDate()).padStart(2, "0"); // Day of the month (1-31), padded to 2 digits
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad to 2 digits
  const year = today.getFullYear(); // Full year (e.g., 2024)

  // Construct the date string in the desired format
  const formattedDate = `${day}/${month}/${year}`;

  return (
    <>
      {Object.keys(studentOfferLetterData).length > 0 ? (
        <>
          <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse no-print fixed right-0">
            <button
              type=""
              className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
          <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
            {/* offerletter */}
            <div className="w-[210mm]  bg-white " id="offerLetter">
              {/* header */}
              <div className="flex gap-1 justify-center mt-2">
                <div className="mt-5">
                  <img
                    src="/assets/img/Acetek_logo.jpg"
                    alt="Acetek Logo"
                    width={140}
                  />
                </div>

                <div>
                  <div className="flex gap-20">
                    <div className="text-[9px] text-[#2C3178] mt-5">
                      cpe Registration No: 200821327E <br />
                      (21 June 2023 to 20 June 2027) <br />
                      EduTrust Certificate No: EDU-3-3198 (29 January 2024 to 28
                      January 2025)
                    </div>
                    <div className="flex-col flex">
                      <span className="text-[#2C3178] font-bold text-[17px] flex justify-end">
                        AceTek College Pte Ltd
                      </span>
                      <div className="flex justify-end">
                        <span className="text-[9px] text-[#2C3178] flex-col">
                          <span className="flex justify-end">
                            Blk 167 Jalan Bukit Merah, #02-12
                          </span>
                          Connection One Tower 5, Singapore 150167
                        </span>
                      </div>
                      <div className="flex gap-2">
                        {/* phone icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LocalPhoneIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            (65) 6970 1370
                          </span>
                        </div>
                        {/* email icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <EmailIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            info@acetech.edu.sh
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-yellow-500 w-full h-1 mt-1"></div>
                </div>
              </div>

              {/* body */}
              <div className="m-10 text-[12px]">
                <div className="text-[12px] flex justify-between">
                  <span>{formattedDate}</span>
                  <span>Ref: ATC/CLOO/{formattedDate}</span>
                </div>
                <div className="flex flex-col gap-4">
                  <span>
                    Dear Ms. {studentOfferLetterData?.name} <br />
                    Passport No: {studentOfferLetterData?.passport} <br />
                    {studentOfferLetterData?.country} <br />
                  </span>
                  <span className="font-semibold uppercase">
                    CONDITIONAL LETTER OF OFFER FOR{" "}
                    {studentOfferLetterData?.course}
                  </span>
                  <span>
                    Thank you for your interest in the above-mentioned course.
                  </span>

                  <span className="me-10 flex flex-col gap-1">
                    With reference to your documents submitted for review, we
                    are pleased to inform you that you have met the minimum
                    entry requirements for the above course and are eligible to
                    apply for admission. Kindly complete and submit the
                    documents (with a certified translation in the English
                    Language if necessary) listed in the attached admission
                    checklist for application purposes. If there is no response
                    from you within two weeks from the date of this letter, we
                    will treat this application as void.
                    <br />
                    <span className="font-semibold">
                      Details of the above course are as follows:
                    </span>
                  </span>
                </div>
                <div className="mt-6">
                  <div className="flex flex-col">
                    <table className="w-full border-collapse">
                      <tbody>
                        <tr className="mb-4">
                          <td className="pb-3 pr-3 text-left">Course:</td>
                          <td className="pb-4 text-left">
                            <span className="font-bold  uppercase">
                              {studentOfferLetterData?.course}
                            </span>
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-4 pr-4 text-left w-48 flex">
                            Commencement Date:
                          </td>
                          <td className="pb-4 text-left whitespace-nowrap">
                            {new Date(
                              studentOfferLetterData?.commencementDate
                            ).toLocaleDateString("en-GB")}
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-8 pr-4 text-left">
                            Duration of Course:
                          </td>
                          <td className="text-left pt-6">
                            {studentOfferLetterData?.durationOfCourse}
                            <br />
                            Participation in an industrial attachment is
                            mandatory and forms an essential component of the
                            course
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-7 pr-4 text-left">
                            Application Fee:
                          </td>
                          <td className="pb-4 text-left pt-8">
                            S${studentOfferLetterData?.applicationFee} inclusive
                            of 9% GST
                            <br />
                            (payable upon application
                            <br />
                            nonrefundable fees)
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-10 pr-4 text-left">Course Fee:</td>
                          <td className="pb-4 text-left pt-4">
                            <span className="font-semibold">
                              S${studentOfferLetterData?.courseFee}{" "}
                            </span>{" "}
                            inclusive of 9% GST <br /> Course Fee is payable
                            after signing the Student Contract between <br />{" "}
                            you and AceTek.
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-1 pt-2 pr-4 text-left">Award:</td>
                          <td className="pb-4 text-left pt-5">
                            DIPLOMA IN HOSPITALITY MANAGEMENT
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pt-2 pr-4 text-left">
                            Awarding Body:
                          </td>
                          <td className="pb-4 text-left pt-5">
                            AceTek College Pte Ltd
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <span className="mt-7">
                      Please email info@acetek.edu.sg if you have any questions.
                    </span>
                    <br />
                    <span>Yours sincerely</span>
                    <br />
                    <span className="mt-11">
                      Sudeep Sreedharan
                      <br />
                      Managing Director
                    </span>
                  </div>
                </div>
              </div>
              {/* <button onClick={handleDownload}>download</button>/ */}

              {/* close body */}
            </div>
          </div>
        </>
      ) : (
        <p>Fetching.....</p>
      )}
    </>
  );
}

export default OfferLetter;
