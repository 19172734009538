import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteCoursesAPI, editCourseAPI, fetchCoursesAPI } from "../../../API/admin/AdminAPI";
import { Modal, Box, Button, TextField } from "@mui/material"; // Importing Material UI components

const CoursesTableRow = ({ course,onUpdateCourse  }) => {
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [editCourseData, setEditCourseData] = useState({
    course: course.course,
    duration: course.duration,
    fees: course.fees,
    applicationFees: course.applicationFees,
  });

  // Open Modal
  const handleOpenModal = () =>{
      
    setOpenModal(true);
    
  } 

  // Close Modal
  const handleCloseModal = () => setOpenModal(false);

  // Handle form field changes
  const handleChange = (e) => {
    setEditCourseData({ ...editCourseData, [e.target.name]: e.target.value });
  };

  // Handle Edit Course Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
       const response = await editCourseAPI(editCourseData,course._id);
      if (response.status === 200) {
        handleCloseModal();
        onUpdateCourse(response.data.course);
        alert("successfully updated ") // Close the modal after successful update
          }
         
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCourse = async () => {
    try {
      const response = await deleteCoursesAPI(course._id);
  
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <tr key={course?._id}>
        <td className="border border-1 border-solid text-left text-sm p-[8px] capitalize hover:underline">
          {course.course}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.duration}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.fees}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.applicationFees}
        </td>
        <td className="text-[30px] text-gray-500 text-center border border-1 border-solid p-[8px]">
          <button
            onClick={handleOpenModal}
            className="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-2 rounded inline-flex items-center"
          >
            <EditIcon />
          </button>
          <button
            onClick={handleDeleteCourse}
            className="ml-2 bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-2 rounded inline-flex items-center"
          >
            <DeleteIcon />
          </button>
        </td>
      </tr>

      {/* Modal for editing the course */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            borderRadius: "20px",
            bgcolor: "background.paper",
            
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Edit Course</h2>
          <TextField
            label="Course Name"
            name="course"
            value={editCourseData.course}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Duration"
            name="duration"
            value={editCourseData.duration}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fees"
            name="fees"
            type="number"
            value={editCourseData.fees}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Application Fees"
            name="applicationFees"
            type="number"
            value={editCourseData.applicationFees}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Box sx={{ mt: 2 }}>
            <Button type="submit" variant="contained" color="primary" style={{borderRadius:"20px"}} fullWidth>
              Update Course
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CoursesTableRow;
