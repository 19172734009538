import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { fetchStudentInvoiceAPI } from "../../API/admin/GenerateStudentDocuments";
import { useParams } from "react-router-dom";

const Invoice = () => {
  const handleDownload = () => {
    window.print();
  };

  const [studentInvoice, setStudentInvoice] = useState([]);
  const [studentInvoiceData, setStudentInvoiceData] = useState({});
  const { paymentId } = useParams();
  let count = 1; // Initialize count

  const fetchStudentOfferLetterData = async () => {
    try {
      const response = await fetchStudentInvoiceAPI(paymentId);
      if (response.status === 200) {
        setStudentInvoice(response.data);
        if (response.data.length > 0) {
          setStudentInvoiceData(response.data[0]);
        }
        document.title = `${response.data[0]?.studentName}-payment-receipt`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStudentOfferLetterData();
  }, [paymentId]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  console.log(studentInvoice);

  const selectDropdownValue = (data) => {
    setStudentInvoiceData(data);
  };

  return (
    <>
      <div className="px-4 py-3 sm:px-6 flex flex-col sm:flex sm:flex-row-reverse no-print fixed right-0">
        <button
          className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleDownload}
        >
          Download
        </button>

        <div className="relative inline-block text-left">
          <button
            id="dropdownDefaultButton"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            onClick={toggleDropdown}
          >
            Invoice (
            {studentInvoice.filter((data) => data?.amountReceived).length})
            <svg
              className="w-2.5 h-2.5 ml-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>

          {isOpen && (
            <div
              id="dropdown"
              className="z-10 absolute mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                {studentInvoice.map((data, index) => (
                  <>
                    {data?.amountReceived ? (
                      <li key={index}>
                        <span
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                          onClick={() => selectDropdownValue(data)}
                        >
                          <span>Invoice({count++})</span>{" "}
                          {/* Use custom count */}
                        </span>
                      </li>
                    ) : null}
                  </>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
        <div className="w-[210mm] bg-white p-6">
          <div className="flex gap-1 justify-center">
            <div className="mt-2">
              <img src="/assets/img/Acetek_logo.jpg" alt="img" width={120} />
            </div>

            <div>
              <div className="flex gap-6">
                <div className="text-[12px] text-[#2C3178] mt-4">
                  cpe Registration No : 200821327E <br />
                  (21 June 2023 to 20 june 2027)
                </div>
                <div className="flex-col flex mt-3 gap-1">
                  <div className="flex gap-4">
                    <div className="flex items-center gap-1">
                      <div className="bg-[#2C3178] w-4 h-4 flex justify-center items-center rounded-xl">
                        <EmailIcon
                          className="text-white"
                          style={{ fontSize: 10 }}
                        />
                      </div>
                      <span className="text-[10px] text-[#2C3178]">
                        info@acetech.edu.sh
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-[#2C3178] w-4 h-4 flex justify-center items-center rounded-xl">
                        <LocalPhoneIcon
                          className="text-white"
                          style={{ fontSize: 10 }}
                        />
                      </div>
                      <span className="text-[10px] text-[#2C3178]">
                        +65 6970 1370
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-[#2C3178] w-4 h-4 flex justify-center items-center rounded-xl">
                        <LanguageIcon
                          className="text-white"
                          style={{ fontSize: 10 }}
                        />
                      </div>
                      <span className="text-[10px] text-[#2C3178]">
                        www.acetek.edu.sg
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="bg-[#2C3178] w-4 h-4 flex justify-center items-center rounded-xl">
                      <LocationOnIcon
                        className="text-white"
                        style={{ fontSize: 10 }}
                      />
                    </div>
                    <span className="text-[10px] text-[#2C3178]">
                      Blk 167 Jalan Bukit Merah, #02-12 Connection One Tower 5,
                      Singapore 150167
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-yellow-500 w-full h-1"></div>
            </div>
          </div>

          <div className="mt-8 m-4 mb-36">
            <div className="flex justify-center">
              <u>
                <h3 className="font-bold">OFFICIAL RECEIPT</h3>
              </u>
            </div>
            <div className="flex flex-col font-semibold   relative -right-[30rem] text-[12px]">
              <span>Receipt No: &nbsp; {studentInvoiceData?.receiptNo} </span>
              <span>
                Date:{" "}
                <span>
                  {new Date(studentInvoiceData?.date).toLocaleDateString(
                    "en-GB"
                  )}
                </span>
              </span>
            </div>

            <div>
              <table>
                <tbody className="text-[12px] mt-10 font-semibold">
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Student Name
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.studentName}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Student NRIC /FIN/ <br />
                      Passport No.
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.passportNo}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 relative bottom-12 border-black w-[200px] h-10">
                      Being Payment for
                    </td>
                    <td className="border border-black w-[560px]">
                      <div className="p-3 pl-9">
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Application fee (including
                          GST){" "}
                          <span className="font-semibold">
                            (Non-refundable)
                          </span>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" checked /> Course fee
                          (including GST)
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.reAssessmentFee}
                          />{" "}
                          Re-Assessment fee (including GST){" "}
                          <span className="font-semibold">
                            (Non-refundable)
                          </span>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.studentPassExtension}
                          />{" "}
                          Student Pass Extension (including GST){" "}
                          <span className="font-semibold">
                            (Non-refundable)
                          </span>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.others}
                          />{" "}
                          Others
                          <div className="h-[1px] bg-black w-full mt-5"></div>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.remarks}
                          />{" "}
                          Remarks
                          <div className="h-[1px] bg-black w-full mt-5"></div>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Amount Received $SGD
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.amountReceived}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Outstanding Balance $SGD
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.outstandingAmount}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 relative bottom-12 border-black w-[200px] h-10">
                      Payment mode
                    </td>
                    <td className="border border-black w-[560px]">
                      <div className="p-3 pl-9">
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Application fee (including
                          GST)
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Bank Deposit/Transfer Slip
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Telegraphic Transfer
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Paynow
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Cheque No./Date
                          <div className="flex">
                            <div className="h-[1px] w-[70px] mt-4 bg-black"></div>
                            /
                            <div className="h-[1px] w-[70px] bg-black mt-4"></div>
                          </div>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Others
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Course Enrolled
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.courseEnrolled}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Issuer’s Name
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.issuerName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
